export function usePage() {

  const getFormattedHeadline = (headline: string) => {
    if (headline && headline.length === 0) return headline;

    return headline
      .replace(
        /<span/g,
        '<span class="text-secondary" '
      )
      .replace(
        /<\/span>/g,
        '</span>'
      );
  };

  return {
    getFormattedHeadline
  };
}
